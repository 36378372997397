import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';

const BackgroundContainer = ({ children, className, fluid }) => {
  return (
    <BackgroundImage
      Tag="div"
      className={className}
      fluid={fluid}
    >
      {children}
    </BackgroundImage>
  );
};

BackgroundContainer.propTypes = {
  children: PropTypes.node,
  fluid: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]).isRequired,
  className: PropTypes.string,
  backgroundPosition: PropTypes.string
};

const StyledBackgroundContainer = styled(BackgroundContainer)`
  background-size: auto;
  ${({ backgroundPosition }) => backgroundPosition && `
    background-position: ${backgroundPosition};
  `}
`;

export default StyledBackgroundContainer;
