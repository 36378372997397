import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { StyledLinkComponent } from './linkComponent.styled';

const LinkComponent = ({ to, children, type, target, className, title }) => {
  return (
    <StyledLinkComponent className={className}>
      {
        type === 'internal' ?
          <Link to={to} target={target} title={title}>{children}</Link> :
          <a href={to} target={target} title={title}>{children}</a>
      }
    </StyledLinkComponent>
  );
};

LinkComponent.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['internal', 'external']),
  target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top']),
  className: PropTypes.string,
  title: PropTypes.string
};

LinkComponent.defaultProps = {
  to: '/',
  type: 'internal',
  target: '_self',
  children: 'Link',
  title: 'Link'
};

export default LinkComponent;
