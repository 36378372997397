import styled from 'styled-components';

export const StyledAppContainer = styled.div`
  height: 100vh;
  scroll-behavior: smooth;
  overflow: auto;
  font-family: ${({ theme }) => theme.fonts.arial};
  
  .resizing-container {
    position: relative;
    min-height: 100vh;
    background: ${({ theme }) => theme.colors.white};
  }

  .scroll-up {
    position: fixed;
    bottom: 20px;
    right: 35px;
    background: #337ab7;
    cursor: pointer;
    border: 0;
    width: 3.5em;
    height: 3.5em;
    display: ${({ scrollPosition }) => scrollPosition >= 265 ? 'flex;' : 'none;'};
    justify-content: center;
    align-items: center;
    z-index: ${({ theme }) => theme.zIndexes.aboveAll};
    opacity: .3;
    transition: all .3s;

    &:focus {
      outline: none;
    }

    &:hover {
      opacity: 1;
    }

    @media only screen and (max-width: ${({ theme }) => theme.breakpoints[2]}) {
      bottom: 70px;
    }
  }
`;