import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

const Seo = ({ description, lang, meta, keywords, title, imageUrl, location }) => {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: description
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: description
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          property: `og:image`,
          content: imageUrl
        },
        {
          property: `og:url`,
          content: location
        }
      ]
        .concat(
          keywords.length > 0
            ? {
              name: `keywords`,
              content: keywords.join(`, `),
            }
            : []
        )
        .concat(meta)}
    />
  );
};

Seo.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [
    'crypto',
    'currency',
    'tycoon',
    'btc',
    'bitcoin',
    'ethereum',
    'coin',
    'token',
    'trade'
  ],
  imageUrl: 'https://i.postimg.cc/rw0tnJXn/12a7d7b27f1e74db3e4a7ab14cf28fc1.png'
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default Seo;
