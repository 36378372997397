import React from 'react';
import PropTypes from 'prop-types';

import { StyledList } from './list.styled';

const List = ({ children, title, direction, withSeparate }) => {
  return (
    <StyledList direction={direction} withSeparate={withSeparate}>
      { title && <h1>{title}</h1> }
      <ul>
        {children.map((child, i) => <li key={i}>{child}</li>)}
      </ul>
    </StyledList>
  );
};

List.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  direction: PropTypes.oneOf(['row', 'column']),
  withSeparate: PropTypes.bool
};

List.defaultProps = {
  children: [],
  direction: 'column',
  withSeparate: false
};

export default List;
