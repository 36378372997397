import React from 'react';
import PropTypes from 'prop-types';

import Loading from '../Loading';
import { StyledButton } from './button.styled';

const Button = ({ children, className, type, handler, title, disabled, viewType, loading }) => {
  return (
    <StyledButton
      className={className}
      type={type}
      onClick={handler}
      title={title}
      disabled={disabled || loading}
      viewType={viewType}
    >
      {
        loading ?
        <Loading size="s" /> :
        children    
      }
    </StyledButton>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  type: PropTypes.oneOf(['submit', 'button', 'reset']),
  handler: PropTypes.func,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  viewType: PropTypes.oneOf(['button', 'text']),
  loading: PropTypes.bool
};

Button.defaultProps = {
  children: 'Click here',
  type: 'button',
  title: 'Click here',
  disabled: false,
  viewType: 'button'
};

export default Button;
