import React from 'react';

import Container from '../Container';
import { StyledContent } from './content.styled'

const Content = ({ children }) => {
  return (
    <Container>
      <StyledContent>
        {children}
      </StyledContent>
    </Container>
  );
}

export default Content;
