import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Reset } from 'styled-reset';

import Seo from '../../components/seo';
import AppContainer from '../AppContainer';
import Header from '../Header';
import Content from '../Content';
import Footer from '../Footer';

const Layout = ({ title, description, imageUrl, children, location }) => {
  useEffect(() => {
    // Adding plugin to share on social networks
    const socialLinks = document.createElement('script');
    socialLinks.setAttribute('src', '//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-609f665f740f62e9');
    document.body.appendChild(socialLinks)
  }, []);

  return (
    <React.StrictMode>
      <Seo
        title={title}
        lang="en"
        description={description}
        location={location}
        imageUrl={imageUrl}
      />
      <Reset />
      <AppContainer>
        <Header />
        <Content>
          {children}
        </Content>
        <Footer />
      </AppContainer>
    </React.StrictMode>
  );
};

Layout.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  description: PropTypes.string,
  imageUrl: PropTypes.string
};

Layout.defaultProps = {
  title: 'Title',
  children: 'Add some content here...'
};

export default Layout;
