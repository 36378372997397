import React, { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { FaBars } from 'react-icons/fa';

import Button from '../../common/components/Button';
import LinkComponent from '../../common/components/LinkComponent';
import Navbar from '../../common/components/Navbar';
import Container from '../Container';
import { StyledHeader } from './header.styletd';

const Header = () => {
  const [isNavbarVisible, setNavbarVisibility] = useState(false);

  const handleClick = () => setNavbarVisibility(!isNavbarVisible);

  return (
    <StyledHeader isNavbarVisible={isNavbarVisible}>
      <Container>
        <div className="header">
          <LinkComponent title="Home">
            <StaticImage src="../../common/assets/image/tycoon-logo.png" alt="Tycoon Logo" className="header_logo" />
          </LinkComponent>
          <div className="header_controls-wrapper">
            <Button
              className="header_controls-wrapper_nav-button"
              title="Menu"
              handler={handleClick}
            >
              <FaBars />
            </Button>
            <Navbar className="header_controls-wrapper_navbar">
              {/* <LinkComponent to="/" className="header_controls-wrapper_navbar_link">About</LinkComponent>
              <LinkComponent to="/" className="header_controls-wrapper_navbar_link">Trade</LinkComponent> */}
              <LinkComponent to="https://tycoon.io/roadmap.html" className="header_controls-wrapper_navbar_link">Roadmap</LinkComponent>
              <LinkComponent to="https://tycoon.io/whitepaper/whitepaper.pdf" className="header_controls-wrapper_navbar_link">Whitepaper</LinkComponent>
            </Navbar>
            <LinkComponent className="header_controls-wrapper_navbar_link__sign-up" to="https://app.tycoon.io/register">Sign up</LinkComponent>
            <Button>Buy Token</Button>
          </div>
        </div>
      </Container>
    </StyledHeader>
  );
};

export default Header;
