import styled from 'styled-components';

export const StyledContent = styled.main`
  padding-bottom: ${({ theme }) => theme.mapFooterHeight.main};

  ${({ theme }) => `
    @media only screen and (max-width: ${theme.breakpoints[1]}) {
      padding-bottom: ${theme.mapFooterHeight[theme.breakpoints[1]]};
    }

    @media only screen and (max-width: ${theme.breakpoints[3]}) {
      padding-bottom: ${theme.mapFooterHeight[theme.breakpoints[3]]};
    }

    @media only screen and (max-width: ${theme.breakpoints[4]}) {
      padding-bottom: ${theme.mapFooterHeight[theme.breakpoints[4]]};
    }
  `}
`;