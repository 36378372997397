import styled from 'styled-components';

export const StyledButton = styled.button`
  cursor: pointer;
  border: none;

  ${({ viewType }) => viewType === 'text' && `
    background-color: transparent;
    padding: 0;
  `}
`;