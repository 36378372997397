import React from 'react';
import PropTypes from 'prop-types';

import spinner from '../../../common/assets/image/spinner.gif';
import { StyledLoading } from './styled.loading';

const Loading = ({ size }) => {
  const sizes = {
    s: {
      width: 30,
      height: 30
    },
    m: {
      width: 60,
      height: 60
    },
    l: {
      width: 100,
      height: 100
    }
  };

  return (
    <StyledLoading>
      <img src={spinner} alt="Loading..." {...sizes[size]} />
    </StyledLoading>
  );
};

Loading.propTypes = {
  size: PropTypes.oneOf(['s', 'm', 'l'])
};

Loading.defaultProps = {
  size: 'm'
};

export default Loading;
