import styled from 'styled-components';

export const StyledFooter = styled.footer`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  
  .footer {
    height: ${({ theme }) => theme.mapFooterHeight.main};
    box-sizing: border-box;
    padding: 20px 20px 80px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: ${({ theme }) => theme.fontSizes.m};
    & * {
      color: ${({ theme }) => theme.colors.white};
    }

    ${({ theme }) => `
      @media only screen and (max-width: ${theme.breakpoints[1]}) {
        height: ${theme.mapFooterHeight[theme.breakpoints[1]]};
      }

      @media only screen and (max-width: ${theme.breakpoints[3]}) {
        height: ${theme.mapFooterHeight[theme.breakpoints[3]]};
      }

      @media only screen and (max-width: ${theme.breakpoints[4]}) {
        height: ${theme.mapFooterHeight[theme.breakpoints[4]]};
      }
    `}

    &_logo {
      display: inline-block;
      width: 70px;
      height: 70px;
    }

    &_social-link {
      & a {
        background-color: ${({ theme }) => theme.colors.darkGray};
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 1.5em;
        height: 1.5em;
        border-radius: 50%;
      }
    }

    &_top {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      &_column {
        flex-basis: 20%;

        @media only screen and (max-width: ${({ theme }) => theme.breakpoints[1]}) {
          flex-basis: 40%;
          margin-bottom: 20px;
        }

        @media only screen and (max-width: ${({ theme }) => theme.breakpoints[4]}) {
          flex-basis: 80%;
          justify-content: center;
        }

        p {
          margin 1.5em 0;
        }

        a {
          line-height: 2;
        }

        .footer_list-item-with-icon {
          display: flex;
          justify-content: flex-start;
          align-items: baseline;
    
          &_icon {
            margin-right: .5em;
            color: ${({ theme }) => theme.colors.darkGray};
          }
        }
      }
    }

    &_bottom {
      display: flex;
      justify-content: space-between;

      @media only screen and (max-width: ${({ theme }) => theme.breakpoints[1]}) {
        flex-direction: column;
        
        & > :first-child {
          margin-bottom: 20px;
        }
      }
    }
  }
`;