import styled from 'styled-components';

export const StyledHeader = styled.header`
  background-color: ${({ theme }) => theme.colors.black};
  position: sticky;
  top: 0;
  z-index: ${({ theme }) => theme.zIndexes.aboveAll};

  .header {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_logo {
      display: inline-block;
      width: 70px;
      height: 70px;

      @media only screen and (max-width: ${({ theme }) => theme.breakpoints[4]}) {
        width: 40px;
        height: 40px;
      }
    }

    &_controls-wrapper {
      display: flex;
      align-items: center;

      &_navbar {
        border-right: 1px solid ${({ theme }) => theme.colors.white};
        padding: 0 1.5em;

        &_link {
          :not(:last-child) {
            margin-right: 1.5em;
          }

          &__sign-up {
            margin-left: 1em;

            & a {
              color: ${({ theme }) => theme.colors.white};
    
              &:visited {
                color: ${({ theme }) => theme.colors.white};
              }
    
              &:hover {
                color: ${({ theme }) => theme.colors.tycoonMain};
              }
            }
          }

          & a {
            color: ${({ theme }) => theme.colors.white};
  
            &:visited {
              color: ${({ theme }) => theme.colors.white};
            }
  
            &:hover {
              color: ${({ theme }) => theme.colors.tycoonMain};
            }
          }

          @media only screen and (max-width: ${({ theme }) => theme.breakpoints[2]}) {
            display: block;

            :not(:last-child) {
              margin-right: 0;
            }

            a {
              display: block;
              padding: 1em 0;
            }
            
            :not(:last-of-type) {
              border-bottom: 1px solid ${({ theme }) => theme.colors.white};
            }
          }
        }

        @media only screen and (max-width: ${({ theme }) => theme.breakpoints[2]}) {
          display: ${({ isNavbarVisible }) => isNavbarVisible ? 'block' : 'none'};
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          background-color: ${({ theme }) => theme.colors.black};
          border-right: none;

          nav {
            display: flex;
            flex-direction: column;
          }
        }
      }

      & > button {
        color: ${({ theme }) => theme.colors.white};

        &:nth-of-type(2) {
          margin: 0 1em;
          font-size: 1em;

          &:hover {
            color: ${({ theme }) => theme.colors.tycoonMain};
          }
        }

        &:last-of-type {
          font-size: 1em;
          border-radius: 3px;
          padding: .5em 1em;
          background-color: ${({ theme }) => theme.colors.tycoonMain};
        }
      }

      &_nav-button {
        background-color: transparent;
        padding: 0;
        font-size: 1.5em;
        display: none;
        align-items: center;

        &:hover {
          color: ${({ theme }) => theme.colors.tycoonMain};
        }

        @media only screen and (max-width: ${({ theme }) => theme.breakpoints[2]}) {
          display: inline-flex;
        }
      }
    }
  }
`;