import React, { useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FaChevronUp } from 'react-icons/fa';

import { getScrollPosition } from '../../redux/selectors';
import { setScrollPosition } from '../../redux/actions';
import Button from '../../common/components/Button';
import { StyledAppContainer } from './appContainer.styled';

const AppContainer = ({ children }) => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const scrollPosition = useSelector(state => getScrollPosition(state));

  const handleScrollPosition = event => dispatch(setScrollPosition(event.target.scrollTop));
  const scrollToTop = useCallback(() => ref.current.scrollTop = 0, [ref]);

  return (
    <StyledAppContainer ref={ref} onScroll={handleScrollPosition} scrollPosition={scrollPosition}>
      <div className="resizing-container">
        <Button className="scroll-up" type="button" title="Scroll Up" handler={scrollToTop}>
          <FaChevronUp color="#fff" size="2.5em" />
        </Button>
        {children}
      </div>
    </StyledAppContainer>
  );
}

export default AppContainer;