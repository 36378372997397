import React from 'react';
import PropTypes from 'prop-types';

const Navbar = ({ children, className }) => {
  return (
    <nav className={className}>
      {children}
    </nav>
  );
};

Navbar.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

Navbar.defaultProps = {
  children: []
};

export default Navbar;
