import styled from 'styled-components';

export const StyledList = styled.div`
  & h1 {
    font-size: ${({ theme }) => theme.fontSizes.h1.s};
    margin-bottom: 1em;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints[1]}) {
      font-size: ${({ theme }) => theme.fontSizes.h1.xs};
      margin-bottom: .5em;
    }
  }

  & ul {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-direction: ${({ direction }) => direction};

    & li {
      ${({ direction, theme }) => direction === 'row' && `
        &:not(:first-child,:last-child) {
          padding: 0 .5em;
        }
        &:first-child {
          padding-right: .5em;
        }
        &:last-child {
          padding-left: .5em;
        }
      `}

      ${({ withSeparate, theme }) => withSeparate && `
        &:not(:last-child) {
          border-right: 1px solid ${theme.colors.white};
        }
      `}
    }
  }
`;