import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import {
  FaDribbble,
  FaInstagram,
  FaTwitter,
  FaYoutube,
  FaMapMarkerAlt,
  FaEnvelope
} from 'react-icons/fa';

import List from '../../common/components/List';
import LinkComponent from '../../common/components/LinkComponent';
import Container from '../Container';
import BackgroundContainer from '../BackgroundContainer';
import { StyledFooter } from './footer.styled';

const Footer = () => {
  const { bgLogoFooter } = useStaticQuery(
    graphql`
      query {
        bgLogoFooter: file(relativePath: {eq: "bg-logo-footer.png"}) {
          childImageSharp {
            fluid(quality: 90, maxHeight: 470) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );
  return (
    <StyledFooter>
      <BackgroundContainer
        fluid={bgLogoFooter.childImageSharp.fluid}
        backgroundPosition="top right"
      >
        <Container>
          <div className="footer">
            <div className="footer_top">
              <div className="footer_top_column">
                <StaticImage src="../../common/assets/image/tycoon-logo-white.png" alt="Tycoon logo" className="footer_logo" />
                <p>
                  Tycoon serves as an interface between professional traders and end users. All activities of the traders are monitored via a secure API connection in real time
              </p>
                <List direction="row">
                  <LinkComponent type="external" to="https://www.instagram.com/tycoon.io/" className="footer_social-link" title="Instagram">
                    <FaInstagram />
                  </LinkComponent>
                  {/* <LinkComponent type="external" to="/" className="footer_social-link" title="Dribble">
                    <FaDribbble />
                  </LinkComponent> */}
                  <LinkComponent type="external" to="https://twitter.com/tycoon" className="footer_social-link" title="Twitter">
                    <FaTwitter />
                  </LinkComponent>
                  <LinkComponent type="external" to="https://www.youtube.com/watch?v=FQf-hT_XEGY" className="footer_social-link" title="YouTube">
                    <FaYoutube />
                  </LinkComponent>
                </List>
              </div>
              <div className="footer_top_column">
                <List title="Quick Links">
                  <LinkComponent to="https://tycoon.io/whitepaper/whitepaper.pdf">Whitepaper</LinkComponent>
                  <LinkComponent to="/blog">Blogs</LinkComponent>
                  <LinkComponent to="https://tycoon.io/roadmap.html">Roadmap</LinkComponent>
                  <LinkComponent to="https://etherscan.io/token/0x3A82D3111aB5faF39d847D46023d9090261A658F">Smart contract</LinkComponent>
                  <LinkComponent to="https://tycoon.io/careers.html">Careers</LinkComponent>
                </List>
              </div>
              <div className="footer_top_column">
                <List title="Services">
                  <LinkComponent to="https://whitebit.com/">Trade on Whitebit</LinkComponent>
                  <LinkComponent to="https://uniswap.org/">Uniswap</LinkComponent>
                  <LinkComponent to="https://global.bittrex.com/">Bittrex</LinkComponent>
                  <LinkComponent to="https://app.tycoon.io/">Platform</LinkComponent>
                  {/* <LinkComponent to="/">Buy Token</LinkComponent> */}
                </List>
              </div>
              <div className="footer_top_column">
                <List title="Reach us">
                  <div className="footer_list-item-with-icon">
                    <FaEnvelope className="footer_list-item-with-icon_icon" />
                    <LinkComponent type="external" target="_blank" to="mailto:support@tycoon.io">
                      support@tycoon.io
                  </LinkComponent>
                  </div>
                  <div className="footer_list-item-with-icon">
                    <FaMapMarkerAlt className="footer_list-item-with-icon_icon" />
                    <LinkComponent to="/">
                      Tycoon Trading Ltd.13
                    Tepeleniou Str <br /> 8010 Paphos, Cyprus
                  </LinkComponent>
                  </div>
                </List>
              </div>
            </div>
            <div className="footer_bottom">
              <div>&copy; 2021 Tycoon Trading Ltd. All rights reserved</div>
              <List direction="row" withSeparate>
                <LinkComponent to="/">Terms & Conditions</LinkComponent>
                <LinkComponent to="/">Privacy Policy</LinkComponent>
                <LinkComponent to="/">Disclaimer</LinkComponent>
              </List>
            </div>
          </div>
        </Container>
      </BackgroundContainer>
    </StyledFooter>
  );
};

export default Footer;
