import styled from 'styled-components';

export const StyledLinkComponent = styled.div`
  display: inline-block;

  & a {
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => theme.colors.tycoonMain};
    }
  }
`;
