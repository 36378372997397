import React from 'react';

import { StyledContainer } from './container.styled';

const Container = ({ children }) => {
  return (
    <StyledContainer>
      {children}
    </StyledContainer>
  );
};

export default Container;